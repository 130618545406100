import React, { useState, useEffect, useMemo } from 'react'
import {
  dateFormatHandler,
  restructureJob,
  permissionsHandler,
  restructureJobFromAlgolia,
} from '../../../functions'
import CompareApplicants from '../compareApplicants'
import ApplyJobModal from '../../Modals/jobApply'
import ApplySuccessModal from '../../Modals/applySuccessModal'
import JobDescription from '../jobDescription'
import GatedContent from '../../gatedContent'
import html2canvas from 'html2canvas'
import axios from 'axios'
import { auth0Roles, strapiURL } from '../../../config'
import { Link } from 'gatsby'
import { useSelector } from 'react-redux'
import { Table, Tag, message, Tooltip, Spin } from 'antd'
import moment from 'moment'
import { navigate } from 'gatsby'
import useGMT from '../../../hooks/useGTM'
import CandidatePermissionModal from '../../Modals/candidatePermission'

const VIEW_DETAIL_CLOSED = 'closed'
const VIEW_DETAIL_EXPIRED = 'expired'
const VIEW_DETAIL_ACTIVE = 'active'
const VIEW_DETAIL_DRAFT = 'draft'

const JobDetailWithCompanyDetail = ({
  jobData,
  isAllowed,
  user,
  userProfile,
  setUserProfile,
  role,
  loadUserProfile,
  openLoginModal,
  openSignupModal,
}) => {
  let jsPDF
  if (typeof window !== 'undefined') {
    jsPDF = require('jspdf').jsPDF
  }

  let _currentJob = restructureJob(jobData)
  let activeJob =
    moment(new Date()) <
    moment(_currentJob?.published_at).add(30, 'days').toDate()
  let _currentJobId
  if (_currentJob) {
    _currentJobId = _currentJob.id.replace('Job_', '')
  }
  const [applyJob, setApplyJob] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [relatedAnimation, setAnimationLoading] = useState(true)
  const [relatedJobs, setRelatedJobs] = useState(null)
  const [employerJobs, setEmployerJobs] = useState(null)
  const [alreadyApplied, setAlreadyApplied] = useState(false)
  const [applyLoader, setApplyLoader] = useState(true)
  const [currentJobEmployer, setCurrentJobEmployer] = useState({})
  const [companyLoader, setCompanyLoader] = useState(true)
  const [memberDetails, setMemberDetails] = useState(null)
  const [detailView, setDetailView] = useState(VIEW_DETAIL_ACTIVE)
  const [savedJobLoading, setSavedJobLoading] = useState(false)
  const [currentJobSaved, setCurrentJobSaved] = useState(false)
  const [permissionModal, setPermissionModal] = useState(false)

  const storeProducts = useSelector(state => state.user).products
  let products = permissionsHandler(storeProducts, user?.role?.id)

  const { pushApplyExternalJobEvent } = useGMT()

  useEffect(() => {
    setAnimationLoading(true)
    axios
      .post(strapiURL + '/candidate-profiles/member/details', {
        username: user?.username,
      })
      .then(res => {
        setMemberDetails(res?.data)
        setAnimationLoading(false)
      })
      .catch(err => {
        setAnimationLoading(false)
        console.log(err)
      })
  }, [])

  useEffect(() => {
    if (jobData?.id) {
      handleCheckJobExpiry()
      handleSaveJobView()
    }
  }, [jobData])

  const handleSaveJobView = async () => {
    await axios.post(strapiURL + `/job-views`, {
      job: jobData.slug,
      candidate_profile: userProfile?.id,
    })
  }

  const jobLocationList = useMemo(() => {
    if (Array?.isArray(jobData?.location)) {
      return jobData?.location
    } else {
      if (jobData?.location) {
        return [jobData.location]
      } else {
        return []
      }
    }

    return []
  }, [jobData?.location])

  const getJobLocation = job => {
    let remoteEnabled = job?.remote_enabled
    let location
    if (job?.location) {
      location = Array.isArray(job.location) ? job.location[0] : job.location
    }

    return remoteEnabled && location
      ? location.location?.formattedAddress + '-Remote'
      : location
      ? location.location?.formattedAddress
      : !location && remoteEnabled
      ? 'Remote'
      : 'Not Specified'
  }

  const handleCheckJobExpiry = async () => {
    const jobId =
      typeof jobData?.id === 'string' && jobData?.id?.indexOf('Job_') !== -1
        ? parseInt(jobData.id.split('Job_')[1])
        : jobData?.id

    const jobStatus = await axios.get(`${strapiURL}/jobs/${jobId}/status`)
    setDetailView(jobStatus?.data?.status)
  }

  useEffect(() => {
    let name = ''
    if (
      user &&
      userProfile &&
      role !== auth0Roles?.Employer.id &&
      role !== auth0Roles?.NamedUser.id
    ) {
      name = userProfile?.username
    }
    setAnimationLoading(true)
    axios
      .post(strapiURL + `/jobs/related/${jobData.slug}?details=true`, {
        candidate_user_name: name,
      })
      .then(res => {
        setRelatedJobs(res.data)
        setAnimationLoading(false)
      })
      .catch(err => {
        setAnimationLoading(false)
        console.log(err)
      })

    axios
      .get(strapiURL + `/jobs/${jobData?.slug}/employer`)
      .then(res => {
        setCurrentJobEmployer(res?.data)
        setCompanyLoader(false)
        axios
          .post(strapiURL + `/jobs/${jobData.slug}/detail/`, {
            candidate_user_name: name,
            employer_id: res?.data?.employerId,
          })
          .then(response => {
            const employerJobsList = (response?.data || []).filter(
              item => !item?.confidential
            )
            setEmployerJobs(
              employerJobsList.sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at)
              )
            )
          })
          .catch(error => {
            console.log(error)
          })
      })
      .catch(err => {
        setCurrentJobEmployer({})
        console.log(err)
        setCompanyLoader(false)
      })
  }, [role, user, userProfile])

  useEffect(() => {
    const checkSavedJob = async () => {
      try {
        setSavedJobLoading(true)
        const { data } = await axios.post(
          strapiURL + '/saved-jobs/checkSavedJob',
          {
            candidate_profile: userProfile.id,
            job: jobData.strapiId,
          }
        )
        setCurrentJobSaved(data.saved)
      } catch (e) {
        console.log(e)
      } finally {
        setSavedJobLoading(false)
      }
    }

    if (
      jobData &&
      user &&
      user.role?.id === auth0Roles.Member.id &&
      userProfile
    ) {
      checkSavedJob()
    }
  }, [jobData, user, userProfile])

  useEffect(() => {
    //  Checking if candidate already applied
    if (
      user &&
      userProfile &&
      role !== auth0Roles?.Employer.id &&
      role !== auth0Roles?.NamedUser.id &&
      jobData.application_method !== 'externalRedirect'
    ) {
      setApplyLoader(true)

      axios
        .post(strapiURL + '/applications/jobs/checkApplication', {
          candidate_profile: userProfile?.id,
          job: jobData?.id?.split('Job_')[1],
        })
        .then(response => {
          if (response?.data) {
            setAlreadyApplied(true)
            setApplyLoader(false)
          }
        })
        .catch(error => {
          setApplyLoader(false)
          console.log(error)
        })
    } else {
      setApplyLoader(false)
    }
  }, [userProfile, user, role])

  const JobCard = ({ job }) => {
    let _curr = restructureJobFromAlgolia(job)
    return (
      <Link className="listingCard" to={`/job-detail/${job.slug}`}>
        <div className="flex items-center p-4">
          {user && isAllowed && !products?.currentSubscription?.pending && (
            <div className="h-14 w-14 rounded-full shadow flex items-center justify-center">
              <div
                className="h-12 w-12 bg-cover bg-center bg-no-repeat rounded-full bg-65"
                style={{
                  backgroundImage: `url(${
                    _curr?.employerDetails?.image?.url ||
                    '/icons/icon-medical.png'
                  })`,
                }}
              />
            </div>
          )}

          <div className="ml-2">
            <div className="flex justify-between items-center">
              <p className="text-xs font-bold font-sans mb-0.5 text-black mb-1">
                {_curr?.title?.length > 27
                  ? _curr.title.substring(0, 27).concat('...')
                  : _curr.title}
              </p>
              {_curr?.exclusive_medreps && (
                <div className="text-[#973151] font-bold text-xs bg-[#905b6d5e] w-fit px-2 py-1 rounded-full ml-2">
                  Exclusive
                </div>
              )}
            </div>
            <div className={`flex items-end mb-1`}>
              <img src="/icons/icon-currency.svg" className="mb-0 pr-0.5" />
              <span className="text-tagSize text-black">
                {_curr?.minSalary} K{' - '}
                {_curr?.maxSalary} K
              </span>
            </div>
            <div className={`flex items-center`}>
              <img src="/icons/icon-location-pin.svg" className="mb-0" />
              <span className="text-last text-location leading-0 ml-1">
                {_curr?.location}
              </span>
            </div>
            <div className="flex items-center mt-2">
              <div className="bg-tag rounded flex py-1">
                <span className="text-tagText text-tagSize mb-0 px-1">
                  {_curr?.primary_function}
                </span>
              </div>
              <div className="bg-tag rounded flex py-1 ml-2">
                <span className="text-tagText text-tagSize mb-0 px-1">
                  {_curr?.product_category}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    )
  }

  const saveJob = async jobId => {
    if (!user || !userProfile) {
      message.info('Please login to save job.')
      return
    }

    try {
      setSavedJobLoading(true)
      const { data } = await axios.post(
        strapiURL + `/candidate-profiles/${userProfile?.id}/saveJob`,
        {
          jobId,
        }
      )
      if (data?.result?.success) {
        message.success('Job saved.')
        setCurrentJobSaved(true)
      } else {
        message.success('Unable to save job.')
      }
    } catch (e) {
      console.log(e)
      message.error('Unable to save job.')
    } finally {
      setSavedJobLoading(false)
    }
  }

  const removeJob = async jobId => {
    if (!user || !userProfile) {
      message.info('Please login to save job.')
      return
    }

    try {
      setSavedJobLoading(true)
      const { data } = await axios.post(
        strapiURL + `/candidate-profiles/${userProfile?.id}/unSaveJob`,
        {
          jobId,
        }
      )
      if (data?.result?.success) {
        message.success('Job unsaved.')
        setCurrentJobSaved(false)
      } else {
        message.success('Unable to unsave job.')
      }
    } catch (error) {
      console.log('unsave job error ---', error)
      message.success('Unable to unsave job.')
    } finally {
      setSavedJobLoading(false)
    }
  }

  const downloadJob = () => {
    if (!user) {
      message.info('Log in to download job details.')
      return
    }
    if (typeof window !== 'undefined') {
      const input = document.getElementById('downloadDiv')
      html2canvas(input).then(canvas => {
        let imgData = canvas.toDataURL('image/png')
        let doc = new jsPDF('p', 'mm')
        let imgWidth = doc.internal.pageSize.getWidth()
        let pageHeight = doc.internal.pageSize.getHeight()
        let imgHeight = (canvas.height * imgWidth) / canvas.width
        let heightLeft = imgHeight
        let position = 0

        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
        heightLeft -= pageHeight

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight
          doc.addPage()
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
          heightLeft -= pageHeight
        }
        doc.save(`${_currentJob?.title?.split(' ').join('_')}.pdf`)
      })
    }
  }

  const yourJobCols = [
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      render: (text, record, index) => {
        let _job = restructureJob(record)
        return (
          <div
            className="md:pointer-event-none"
            onClick={() => {
              navigate(`/job-detail/${_job.slug}`)
            }}
          >
            <div className="flex">
              {user && isAllowed && !products?.currentSubscription?.pending && (
                <div className="h-10 w-10 rounded shadow flex items-center justify-center">
                  <div
                    className="h-8 w-8 bg-cover bg-center bg-no-repeat"
                    style={{
                      backgroundImage: `url(${
                        _job?.employerDetails?.image?.url ||
                        '/icons/icon-medical.png'
                      } )`,
                    }}
                  />
                </div>
              )}
              <div className="flex justify-between ml-2 flex-1 items-center">
                <div className="">
                  <p className="text-xs font-bold font-sans mb-0.5">
                    {_job?.title || ''}
                  </p>
                  <div className="flex items-center ">
                    <img src="/icons/icon-location-pin.svg" className="mb-0" />
                    <span className="text-last text-location leading-0 ml-1">
                      {getJobLocation(record)}
                    </span>
                    {record?.suggested ? (
                      <span className="text-tagSize mb-0 ml-1 bg-voyage text-white font-sans px-2 rounded-full h-4 flex items-center">
                        Suggested
                      </span>
                    ) : (
                      <div />
                    )}
                  </div>
                  <div className="bg-tag rounded flex py-1 w-max md:hidden">
                    <span className="text-tagText text-tagSize mb-0 px-1">
                      {_job?.primary_function}
                    </span>
                  </div>
                </div>

                {_job?.exclusive_medreps && (
                  <div className="text-[#973151] font-bold text-xs bg-[#905b6d5e] w-fit px-2 py-1 rounded-full mr-2">
                    Exclusive
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      title: 'Function',
      dataIndex: 'function',
      key: 'function',
      render: (text, record, index) => {
        let _job = restructureJob(record)
        return (
          <div>
            <p className="text-location text-last font-sans mb-0">Function</p>
            <p className="text-last font-sans mb-0">{_job.primary_function}</p>
          </div>
        )
      },
      responsive: ['lg'],
    },

    {
      title: 'Exp',
      dataIndex: 'exp',
      key: 'exp',
      render: (text, record, index) => {
        let _job = restructureJob(record)
        return (
          <div>
            <p className="text-location text-last font-sans mb-0">
              Date Expires
            </p>
            <p className="text-last font-sans mb-0">
              {_job.archived_date
                ? dateFormatHandler(_job.archived_date)
                : _job.published_at &&
                  dateFormatHandler(
                    moment(_job.published_at).add(30, 'days').toDate()
                  )}
            </p>
          </div>
        )
      },
      responsive: ['lg'],
    },

    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record, index) => {
        let _job = restructureJob(record)
        return (
          <Link to={`/job-detail/${_job.slug}`}>
            <button className="bg-voyage py-1 px-3 font-sans text-white text-last font-bold rounded mr-2">
              View
            </button>
          </Link>
        )
      },
      responsive: ['lg'],
    },
  ]

  const externalApply = applicationUrl => {
    pushApplyExternalJobEvent({
      jobid: _currentJob.strapiId,
      jobIndustry: _currentJob.product_category || '',
      jobFunction: _currentJob.primary_function || '',
      jobPostingDate: new Date(_currentJob.published_at).toDateString(),
      jobLocation: _currentJob.location,
    })

    // Open job in new link
    window.open(applicationUrl, '_blank')
    // Submit application against that job
    var bodyFormData = new FormData()
    let data = {
      job: parseInt(_currentJob.id.split('Job_')[1]),
      candidate_profile: userProfile?.id,
      appliedAt: new Date(),
      status: 'Active',
      cover_letter_text: 'External Job',
    }
    bodyFormData.append('data', JSON.stringify(data))
    axios({
      method: 'post',
      url: strapiURL + `/applications`,
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
    }).catch(err => {
      console.log(err)
    })
    // Update apply count for that job
    axios
      .put(strapiURL + `/jobs/applyCount/${_currentJob.slug}`)
      .then(res => {})
      .catch(err => {
        console.log(err)
      })
  }

  if (detailView === VIEW_DETAIL_CLOSED) {
    return (
      <div className="container mx-auto py-8">
        <div className="text-merlot text-base	bold border-t-4 border-[#6d1e36] p-4">
          This Page No Longer Exists, please search for jobs{' '}
          <Link to="/job-search">Here</Link>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="container mx-auto py-8">
        {detailView === VIEW_DETAIL_EXPIRED && (
          <div className="text-merlot text-base	bold border-t-4 border-[#6d1e36] p-4">
            This job has expired, please search for active jobs{' '}
            <Link to="/job-search">here</Link>
          </div>
        )}
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="w-full lg:w-3/4 px-[30px] lg:px-0">
            <div id="downloadDiv">
              {!companyLoader ? (
                <div className="bg-white shadow-profileCard w-full rounded-lg">
                  <div className="w-full border-b border-bgSecondaryButton flex flex-col lg:flex-row justify-between p-4 items-start lg:items-center">
                    <div className="flex items-center">
                      {user &&
                        isAllowed &&
                        !products?.currentSubscription?.pending && (
                          <div className="h-14 w-14 rounded shadow flex items-center justify-center">
                            {_currentJob?.confidential ? (
                              <div
                                className="h-8 w-8 bg-cover bg-center bg-no-repeat"
                                style={{
                                  backgroundImage: `url(${'/images/confident-logo.png'})`,
                                }}
                              ></div>
                            ) : (
                              <div
                                className="h-8 w-8 bg-cover bg-center bg-no-repeat"
                                style={{
                                  backgroundImage: `url(${
                                    currentJobEmployer?.image?.url ||
                                    '/icons/icon-medical.png'
                                  })`,
                                }}
                              ></div>
                            )}
                          </div>
                        )}

                      <div className="ml-3">
                        {user &&
                          products?.subscriptionPurchased &&
                          !products?.currentSubscription?.pending &&
                          !_currentJob?.confidential && (
                            <p className="font-sans text-base font-semibold mb-0">
                              {currentJobEmployer?.name || 'Not Specified'}
                            </p>
                          )}
                        <p className="text-sm lg:text-lg font-playfair mb-0">
                          {_currentJob.title}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center mt-5 lg:mt-0">
                      {_currentJob?.exclusive_medreps && (
                        <div className="text-[#973151] font-bold text-xs bg-[#905b6d5e] w-fit px-2 py-1 rounded-full mr-2">
                          Exclusive
                        </div>
                      )}
                      {!applyLoader && alreadyApplied ? (
                        <div className="">
                          <Tag color="green">Applied</Tag>
                        </div>
                      ) : !applyLoader &&
                        isAllowed &&
                        !_currentJob?.archived_date &&
                        activeJob &&
                        detailView === VIEW_DETAIL_ACTIVE &&
                        role === auth0Roles.Member.id ? (
                        <>
                          <div className="flex items-center mt-8 md:mt-0">
                            <div>
                              {_currentJob?.application_method ===
                              'externalRedirect' ? (
                                <button
                                  onClick={() => {
                                    if (
                                      products?.currentSubscription?.pending
                                    ) {
                                      setPermissionModal(true)
                                    } else {
                                      externalApply(_currentJob.applicationUrl)
                                    }
                                  }}
                                  className="bg-voyage py-3 px-5 font-sans text-white text-last font-bold rounded mr-2 mt-0"
                                >
                                  Apply On Website
                                </button>
                              ) : (
                                role === auth0Roles.Member.id && (
                                  <button
                                    onClick={() => {
                                      if (
                                        products?.currentSubscription?.pending
                                      ) {
                                        setPermissionModal(true)
                                      } else {
                                        setApplyJob(_currentJob)
                                      }
                                    }}
                                    className="bg-voyage py-3 px-5 font-sans text-white text-last font-bold rounded mr-2 mt-0"
                                  >
                                    Apply Now
                                  </button>
                                )
                              )}
                            </div>

                            <button
                              onClick={() => downloadJob()}
                              className="bg-white shadow rounded cursor-pointer mr-2 h-11 w-14 flex items-center justify-center"
                            >
                              <Tooltip
                                placement="topLeft"
                                title="Print job details"
                              >
                                <img
                                  src="/icons/icon-printer.svg"
                                  className="mb-0 p-2"
                                />
                              </Tooltip>
                            </button>

                            {userProfile?.applications?.find(
                              item => item.job === parseInt(_currentJob.id)
                            ) ? (
                              <Tooltip
                                placement="topRight"
                                title="Can't save already applied job"
                              >
                                <img
                                  src="/icons/icon-bookmark.svg"
                                  className="mb-0 p-2 h-8 w-8"
                                />
                              </Tooltip>
                            ) : (
                              <>
                                {!user || role === auth0Roles.Member.id ? (
                                  <>
                                    {!savedJobLoading ? (
                                      <button
                                        onClick={() => {
                                          if (currentJobSaved) {
                                            removeJob(_currentJobId)
                                          } else {
                                            saveJob(_currentJobId)
                                          }
                                        }}
                                        className="bg-white shadow rounded cursor-pointer h-11 w-14 flex items-center justify-center"
                                      >
                                        {currentJobSaved ? (
                                          <span className="text-tagSize text-voyage">
                                            Saved
                                          </span>
                                        ) : (
                                          <Tooltip
                                            placement="topRight"
                                            title="Save job to your Dashboard"
                                          >
                                            <img
                                              src="/icons/icon-bookmark.svg"
                                              className="mb-0 p-2"
                                            />
                                          </Tooltip>
                                        )}
                                      </button>
                                    ) : (
                                      <Spin />
                                    )}
                                  </>
                                ) : null}
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                  <div className="p-4">
                    <div className="flex flex-col md:flex-row items-start justify-between">
                      <div className="h-full w-full md:w-[46%]">
                        <div className="h-full">
                          <h1 className="font-normal font-sans text-base mb-0 pb-3">
                            General Information
                          </h1>
                          <div className="flex flex-wrap justify-between content-between w-full h-full">
                            <div className="w-[33%]">
                              <span className="text-xs font-sans text-location">
                                Location
                              </span>
                              {jobLocationList &&
                                jobLocationList.map(locItem => (
                                  <p className="mb-0 font-sans text-last">
                                    {locItem?.location?.formattedAddress}
                                  </p>
                                ))}
                              {jobData?.remote_enabled && (
                                <p className="mb-0 font-sans text-last">
                                  Remote
                                </p>
                              )}
                            </div>
                            <div className="w-[33%]">
                              <span className="text-xs font-sans text-location">
                                Job Code
                              </span>
                              <p className="mb-0 font-sans text-last">
                                {' '}
                                {_currentJob?.job_code}
                              </p>
                            </div>
                            <div className="w-[33%]">
                              <span className="text-xs font-sans text-location">
                                Function
                              </span>
                              <p className="mb-0 font-sans text-last">
                                {_currentJob?.primary_function}
                              </p>
                            </div>
                            <div className="w-[33%] pt-4">
                              <span className="text-xs font-sans text-location">
                                Date Expires
                              </span>
                              <p className="mb-0 font-sans text-last">
                                {_currentJob.archived_date
                                  ? dateFormatHandler(_currentJob.archived_date)
                                  : dateFormatHandler(
                                      moment(_currentJob.published_at)
                                        .add(30, 'days')
                                        .toDate()
                                    )}
                              </p>
                            </div>
                            <div className="w-[33%] pt-4">
                              <span className="text-xs font-sans text-location">
                                Travel Percentage
                              </span>
                              <p className="mb-0 font-sans text-last">
                                {' '}
                                {_currentJob.travel_percentage.replace(
                                  '_',
                                  '-'
                                )}
                              </p>
                            </div>
                            <div className="w-[33%] pt-4">
                              <span className="text-xs font-sans text-location">
                                Industry
                              </span>
                              <p className="mb-0 font-sans text-last">
                                {_currentJob.product_category}
                              </p>
                            </div>
                            <div className="w-[33%] pt-4">
                              <span className="text-xs font-sans text-location">
                                Published At
                              </span>
                              <p className="mb-0 font-sans text-last">
                                {dateFormatHandler(_currentJob.published_at)}
                              </p>
                            </div>
                            <div className="w-[33%] pt-4">
                              <span className="text-xs font-sans text-location">
                                Posted By
                              </span>
                              <p className="mb-0 font-sans text-last">
                                {_currentJob.employerDetails?.staffing_firm
                                  ? 'Recruiting/Staffing Firm'
                                  : 'Direct Employer'}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="h-full w-full md:w-[46%] mt-5 md:mt-0">
                        <div className="h-full">
                          <h1 className="font-normal font-sans text-base mb-0 pb-3">
                            Compensation Information
                          </h1>
                          <div className="flex flex-wrap justify-between w-[80%] content-between h-full">
                            <div className="w-[50%]">
                              <span className="text-xs font-sans text-location">
                                Base Salary
                              </span>
                              <p className="mb-0 font-sans text-last">
                                {!_currentJob.minSalary &&
                                !_currentJob.maxSalary
                                  ? 'Not Specified'
                                  : `$${_currentJob.minSalary || 0}K - $${
                                      _currentJob.maxSalary
                                    }K`}
                              </p>
                            </div>
                            <div className="w-[50%]">
                              <span className="text-xs font-sans text-location">
                                Avg. Total Comp
                              </span>
                              <p className="mb-0 font-sans text-last">
                                {!_currentJob.min_compensation &&
                                !_currentJob.max_compensation
                                  ? 'Not Specified'
                                  : `$${
                                      _currentJob.min_compensation || 0
                                    }K - $${_currentJob.max_compensation}K`}
                              </p>
                            </div>
                            <div className="w-[50%] pt-4">
                              <span className="text-xs font-sans text-location">
                                Compensation Type
                              </span>
                              <p className="mb-0 font-sans text-last">
                                {' '}
                                {_currentJob.compensation_type}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-8">
                      <GatedContent
                        openLoginModal={openLoginModal}
                        openSignupModal={openSignupModal}
                      >
                        <div className="pt-8">
                          <h1 className="font-normal font-sans text-base mb-0">
                            Job Description
                          </h1>
                          <div className="pt-4 font-sans job-desc-area job-description-detail overflow-auto">
                            <JobDescription
                              description={_currentJob.description}
                            />
                          </div>
                          {!applyLoader && alreadyApplied ? (
                            <Tag color="green">Applied</Tag>
                          ) : !applyLoader &&
                            isAllowed &&
                            !_currentJob?.archived_date &&
                            activeJob &&
                            detailView === VIEW_DETAIL_ACTIVE &&
                            role === auth0Roles.Member.id ? (
                            <>
                              {_currentJob?.application_method ===
                              'externalRedirect' ? (
                                <button
                                  onClick={() => {
                                    externalApply(_currentJob.applicationUrl)
                                  }}
                                  className="bg-voyage py-3 px-5 font-sans text-white text-last font-bold rounded mr-2 mt-5 lg:mt-0"
                                >
                                  Apply On Website
                                </button>
                              ) : (
                                <button
                                  onClick={() => setApplyJob(_currentJob)}
                                  className="w-full lg:w-auto bg-voyage py-3 px-5 font-sans text-white text-last font-bold rounded mt-4"
                                >
                                  Apply Now
                                </button>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </GatedContent>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="rounded-lg bg-white shadow-profileCard mt-4">
                  <div
                    className="w-full h-[400px] bg-no-repeat bg-cover"
                    style={{
                      backgroundImage: `url(/loaders/job-description-loader.png)`,
                    }}
                  />
                </div>
              )}
              {user && isAllowed && !products?.currentSubscription?.pending && (
                <div className="bg-white shadow-profileCard w-full rounded-lg mt-4 overflow-auto">
                  <div className="flex px-4 py-4 border-b border-bgSecondaryButton">
                    <img src="/icons/icon-profile.svg" className="mb-0" />
                    <h1 className="font-playfair text-base lg:text-xl font-normal mb-0 ml-4">
                      Company Information
                    </h1>
                  </div>
                  {companyLoader ? (
                    <div
                      className="min-h w-full h-[400px] bg-no-repeat bg-contain bg-center"
                      style={{
                        backgroundImage: `url(/loaders/company-info-loader.png)`,
                      }}
                    />
                  ) : (
                    <div className="p-4">
                      <div className="flex items-center">
                        {isAllowed && (
                          <>
                            {_currentJob?.confidential ? (
                              <div className="h-16 w-16 mr-4">
                                <div
                                  className="w-full h-full bg-no-repeat bg-contain bg-center"
                                  style={{
                                    backgroundImage: `url(${'/images/confident-logo.png'})`,
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="h-16 w-16 mr-4">
                                <div
                                  className="w-full h-full bg-no-repeat bg-contain bg-center"
                                  style={{
                                    backgroundImage: `url(${
                                      currentJobEmployer?.image?.url ||
                                      '/icons/icon-medical.png'
                                    })`,
                                  }}
                                />
                              </div>
                            )}
                          </>
                        )}

                        <div>
                          {_currentJob?.confidential ? (
                            <p className="font-sans text-sm font-semibold mb-0.5">
                              Confidential
                            </p>
                          ) : (
                            <p className="font-sans text-sm font-semibold mb-0.5">
                              {currentJobEmployer?.name || 'Not Specified'}
                            </p>
                          )}
                        </div>
                      </div>
                      {!_currentJob?.confidential && (
                        <div className="mt-4">
                          <p className="font-sans text-xs mb-3 font-bold">
                            About
                          </p>
                          {currentJobEmployer?.description ? (
                            <div
                              className="job-desc-area employer-detail-area"
                              dangerouslySetInnerHTML={{
                                __html: currentJobEmployer?.description,
                              }}
                            />
                          ) : (
                            <p>Not Specified</p>
                          )}
                          <div className="flex mt-4">
                            <div className="w-1/2">
                              <p className="font-sans text-last font-normal mb-0">
                                Address
                              </p>
                              <p className="font-sans text-xs font-semibold mb-0">
                                {currentJobEmployer?.employerLocation?.location
                                  ?.formattedAddress || 'Not Specified'}
                              </p>
                            </div>
                            <div className="w-1/2">
                              <p className="font-sans text-last font-normal mb-0">
                                Website
                              </p>
                              {currentJobEmployer?.website ? (
                                <a
                                  target="_blank"
                                  href={currentJobEmployer?.website}
                                  className="font-sans text-xs font-semibold mb-0 text-black underline"
                                >
                                  {currentJobEmployer?.website}
                                </a>
                              ) : (
                                <p className="font-sans text-xs font-semibold mb-0 text-black">
                                  Not Specified
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>

            {employerJobs?.length &&
            !products?.currentSubscription?.pending &&
            !_currentJob?.confidential ? (
              <div className="rounded-lg bg-white shadow-profileCard mt-4">
                <div className="flex px-4 py-4 border-b border-bgSecondaryButton ">
                  <img src="/icons/icon-baksa.svg" className="mb-0" />
                  <h1 className="font-playfair text-base lg:text-xl font-normal mb-0 ml-4">
                    More Jobs From This Company
                  </h1>
                </div>
                {!relatedAnimation ? (
                  <Table
                    className="table-with-pagination"
                    rowClassName={(record, index) => {
                      return record?.suggested
                        ? 'bg-activeRow hover:bg-activeRow'
                        : 'bg-white'
                    }}
                    columns={yourJobCols}
                    dataSource={employerJobs}
                    showHeader={false}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="w-full lg:w-1/4 px-[30px] lg:px-0">
            <div className="rounded-lg shadow-profileCard p-4 hidden lg:block">
              {_currentJob ? (
                <CompareApplicants
                  currentJob={_currentJob.slug}
                  slug={_currentJob}
                  user={user}
                  isAllowed={isAllowed}
                  textClass="text-left"
                  hideLoginModal={true}
                  memberDetails={memberDetails}
                />
              ) : (
                <div />
              )}
            </div>
            {relatedJobs?.length ? (
              <div className="mt-4">
                <h1 className="font-playfair text-lg font-normal">
                  Related Jobs
                </h1>

                <>
                  {relatedJobs.map((relatedJob, index) => {
                    return (
                      <div className="rounded-lg shadow-profileCard mb-4">
                        <JobCard job={relatedJob} key={index} />
                      </div>
                    )
                  })}
                </>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
      <ApplyJobModal
        loadUserProfile={loadUserProfile}
        currentJob={applyJob}
        jobEmployer={currentJobEmployer}
        setApplyJob={setApplyJob}
        userProfile={userProfile}
        setUserProfile={setUserProfile}
        setSuccessModal={setSuccessModal}
      />
      <CandidatePermissionModal
        visible={permissionModal}
        user={user}
        onClose={() => setPermissionModal(false)}
      />

      <ApplySuccessModal modalOpen={successModal} />
    </>
  )
}
export default JobDetailWithCompanyDetail
